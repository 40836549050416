import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import FormAlertError from 'modules/form/components/FormAlertError'
import FormHelperTextError from 'modules/input/components/FormHelperTextError'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useCreateAccount } from 'services/api/iam/manager/mutations/useCreateAccount'
import { CreateAccountFormType } from 'views/createAccount/types/CreateAccountFormType'

export default function CreateAccountForm() {
  const { t } = useTranslation()
  const {
    token: [token]
  } = useAuthenticationContext()
  const { mutateAsync, error, isError, isLoading } = useCreateAccount()

  const { control, handleSubmit, getValues } = useForm<CreateAccountFormType>({
    defaultValues: {
      name: ''
    },
    resolver: joiResolver(
      Joi.object({
        name: Joi.string()
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const sendCreateAccount = useCallback(async () => {
    mutateAsync({
      token: token?.token!,
      body: getValues()
    })
  }, [mutateAsync, getValues, token])

  const handleSendCreateAccount = useMemo(
    () => handleSubmit(sendCreateAccount),
    [handleSubmit, sendCreateAccount]
  )

  return (
    <Box>
      {isError && (
        <Box marginBottom="40px">
          <FormAlertError message={t(error.response?.data.code || error.message)} />
        </Box>
      )}

      <form>
        <Stack gap="40px">
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl required>
                <FormLabel>{t('Nome da sua loja')}</FormLabel>
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  placeholder={t('Digite o nome da sua loja')}
                  helperText={
                    <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                  }
                />
              </FormControl>
            )}
          />

          <LoadingButton
            onClick={handleSendCreateAccount}
            loading={isLoading}
            type="submit"
            size="large"
            variant="contained"
          >
            {t('Criar loja')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  )
}
