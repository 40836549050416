import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { CreateAccountRequest } from './interfaces/CreateAccountRequest'

export type CreateAccountParams = {
  token: string
  body: CreateAccountRequest
}

export const useIamManagerAccount = () => {
  const endpoint = 'iam-manager/account'

  const createAccount = useCallback(
    (params: CreateAccountParams) =>
      axiosInstance.post(endpoint, params.body, {
        headers: { 'X-Authorization': `Bearer ${params.token}` }
      }),
    []
  )

  return {
    endpoint,
    createAccount
  }
}
