import { styled, Typography } from '@mui/material'
import { ReactComponent as LogoEcomece } from 'assets/icons/logo-ecomece.svg'
import LeftAreaImageLogin from 'assets/images/left-area-image-login.png'
import LeftAreaImageRegister from 'assets/images/left-area-image-register.png'
import UserMenu from 'modules/authentication/components/UserMenu'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import useImageProcessor from 'modules/image/hooks/useImageProcessor'
import PasswordRequestProvider from 'modules/passwordRequest/providers/PasswordRequestProvider'
import { FC, useMemo } from 'react'
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom'
import { routes } from 'routes'
import useGetSalesInformationByResellerSlug from 'services/ais/partner/queries/useGetSalesInformationByResellerSlug'
import { TokenTypeEnum } from 'services/api/iam/manager/enums/TokenTypeEnum'

const LayoutDiv = styled('div', { name: 'LayoutDiv' })(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex'
}))

const LeftArea = styled('div', { name: 'LeftArea' })(({ theme }) => ({
  width: '767px',
  minHeight: '100vh',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  },
  background:
    'transparent linear-gradient(180deg, #77D0AD 0%, #9FE6C0 100%) 0% 0% no-repeat padding-box'
}))

const LeftAreaLogin = styled('div', { name: 'LeftAreaLogin' })({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
  height: '100%'
})

const LeftAreaLoginTitle = styled(Typography, { name: 'LeftAreaLoginTitle' })({
  fontWeight: 'lighter',
  color: '#FFF',
  textAlign: 'center'
})

const LeftAreaRegister = styled('div', {
  name: 'LeftAreaRegister'
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
})

const RightArea = styled('div', { name: 'RightArea' })(({ theme }) => ({
  width: 'calc(100% - 767px)',
  height: '100vh',
  backgroundColor: '#FFF',
  padding: '50px 108px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'center',
  overflowX: 'hidden',
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '25px 25px 75px 25px',
    justifyContent: 'center'
  }
}))

const RightAreaContent = styled('div', { name: 'RightAreaContent' })(({ theme }) => ({
  maxWidth: '383px',
  minWidth: '383px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    minWidth: '100%',
    height: '100%'
  }
}))

const RightAreaHeader = styled('div', {
  name: 'RightAreaHeader',
  shouldForwardProp: prop => prop !== 'isAuthenticated'
})<{ isAuthenticated: boolean }>(({ theme, isAuthenticated }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '60px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: isAuthenticated ? 'space-between' : 'center',
    marginBottom: '56px'
  }
}))

const LayoutMoovinLogoWrapper = styled('div', { name: 'LayoutMoovinLogoWrapper' })(({ theme }) => ({
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  gap: 15,

  '> img:first-child': {
    maxWidth: '149.5px'
  },

  '> img:last-child': {
    maxWidth: '112.39px'
  },

  '> img': {
    objectFit: 'contain',
    maxHeight: '50px'
  },

  [theme.breakpoints.down('sm')]: {
    height: '33px',
    '> img': {
      width: '141px'
    }
  }
}))

export const Layout: FC<LayoutProps> = () => {
  const content = useRoutes(routes)

  const { process } = useImageProcessor()

  const location = useLocation()

  const path = location.pathname.split('/')

  const {
    token: [token]
  } = useAuthenticationContext()

  const isAuthenticated = useMemo<boolean>(() => token?.type === TokenTypeEnum.AUTH, [token])

  const isViewLogin = useMemo<boolean>(() => {
    return path[1] !== 'register' && path[1] !== 'create-account'
  }, [path])

  const isViewRegister = useMemo<boolean>(
    () => path[1] === 'register' || path[1] === 'create-account',
    [path]
  )

  const [searchParams] = useSearchParams()

  const { data: salesInformation } = useGetSalesInformationByResellerSlug(
    searchParams.get('reseller') || undefined,
    {
      suspense: true
    }
  )

  const partnerLogo = useMemo(
    () => salesInformation?.partner.logo,
    [salesInformation?.partner.logo]
  )

  const registerBanner = useMemo(() => {
    if (salesInformation?.partner.banner)
      return process(salesInformation.partner.banner, {
        resize: {
          fitIn: true,
          width: 767,
          height: 0
        }
      })

    return LeftAreaImageRegister
  }, [salesInformation?.partner.banner, process])

  return (
    <LayoutDiv>
      <LeftArea>
        {isViewLogin && (
          <LeftAreaLogin>
            <LeftAreaLoginTitle fontSize="35px">
              Mantenha seu <b>estoque sempre atualizado e aumente</b> as chances de <b>venda</b>
            </LeftAreaLoginTitle>

            <img
              src={LeftAreaImageLogin}
              alt="Mantenha seu estoque sempre atualizado e aumente as chances de venda"
            />
          </LeftAreaLogin>
        )}

        {isViewRegister && (
          <LeftAreaRegister>
            <img src={registerBanner} alt="Venda nos maiores Marketplaces!" />
          </LeftAreaRegister>
        )}
      </LeftArea>

      <RightArea>
        <RightAreaContent>
          <RightAreaHeader isAuthenticated={isAuthenticated}>
            <LayoutMoovinLogoWrapper>
              <LogoEcomece />

              {!isViewLogin && partnerLogo && <Typography variant="h3">+</Typography>}

              {!isViewLogin && partnerLogo && (
                <img
                  src={process(partnerLogo, {
                    resize: {
                      fitIn: true,
                      width: 120,
                      height: 40
                    },
                    filters: { fill: 'FFF' }
                  })}
                  alt="Logo parceiro Moovin"
                />
              )}
            </LayoutMoovinLogoWrapper>

            {isAuthenticated && <UserMenu />}
          </RightAreaHeader>

          <PasswordRequestProvider>
            <div className="content">{content}</div>
          </PasswordRequestProvider>
        </RightAreaContent>
      </RightArea>
    </LayoutDiv>
  )
}

type LayoutProps = {}
