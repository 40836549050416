import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import useManagerTokenInviteContext from './hooks/useManagerTokenInviteContext'
import { useGetInviteStatus } from 'services/api/iam/manager/queries/useGetInviteStatus'
import { useTranslation } from 'hooks/useTranslation'
import { Trans } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { useCallback, useState } from 'react'
import {
  useAcceptInvite,
  useAcceptingInvite
} from 'services/api/iam/manager/mutations/useAcceptInvite'
import DeclineInvitationModal from './components/DeclineInvitationModal'
import InviteRegisterForm from './components/InviteRegisterForm'

export default function Invite() {
  const { t } = useTranslation()
  const [openDeclineInvitationModal, setOpenDeclineInvitationModal] = useState<boolean>(false)
  const [openedFormRegister, setOpenedFormRegister] = useState<boolean>(false)
  const { token, decodedToken } = useManagerTokenInviteContext()
  const { data: inviteStatus, isLoading: isLoadingGetInviteStatus } = useGetInviteStatus({
    id: decodedToken ? decodedToken.id : '',
    token
  })
  const { mutateAsync } = useAcceptInvite()
  const isLoadingAcceptingInvite = useAcceptingInvite()

  const onClickButtonRegister = useCallback(() => {
    if (inviteStatus?.userExists) {
      mutateAsync({ id: decodedToken!.id, token })
      return
    }

    setOpenedFormRegister(true)
  }, [decodedToken, inviteStatus?.userExists, mutateAsync, token])

  return (
    <Box>
      {isLoadingGetInviteStatus && (
        <Box display="flex" justifyContent="center" padding="50px">
          <CircularProgress size={60} sx={[{ color: 'primary.main' }]} />
        </Box>
      )}

      {!isLoadingGetInviteStatus && inviteStatus && !openedFormRegister && (
        <Box>
          {inviteStatus.status.toLowerCase() === 'pending' ? (
            <Box>
              <Stack gap="30px" marginBottom="40px">
                <Typography variant="h6" fontWeight="fontWeightBold">
                  {t('Você recebeu um convite!')}
                </Typography>
                <Typography variant="subtitle1">
                  <Trans
                    components={{ b: <b /> }}
                    i18nKey="Você foi convidado por {{invitedBy}} para fazer parte da equipe."
                    values={{ invitedBy: decodedToken?.account.name }}
                    t={t}
                  />
                </Typography>
              </Stack>

              <Stack gap="25px">
                <LoadingButton
                  onClick={() => onClickButtonRegister()}
                  loading={isLoadingAcceptingInvite}
                  variant="contained"
                >
                  {t('Aceitar e cadastrar')}
                </LoadingButton>

                <Button
                  onClick={() => setOpenDeclineInvitationModal(true)}
                  color="error"
                  variant="outlined"
                >
                  {t('Recusar convite')}
                </Button>
              </Stack>
            </Box>
          ) : (
            <Typography variant="h5" fontWeight="fontWeightBold">
              {t('Convite inválido!')}
            </Typography>
          )}
        </Box>
      )}

      {openedFormRegister && (
        <InviteRegisterForm setOpenDeclineInvitationModal={setOpenDeclineInvitationModal} />
      )}

      <DeclineInvitationModal
        open={openDeclineInvitationModal}
        handleClose={() => setOpenDeclineInvitationModal(!openDeclineInvitationModal)}
      />
    </Box>
  )
}
