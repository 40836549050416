export const isValidEmail = (strEmail: string) => {
  const user = strEmail.substring(0, strEmail.indexOf('@'))
  const domain = strEmail.substring(strEmail.indexOf('@') + 1, strEmail.length)
  if (
    user.length >= 1 &&
    domain.length >= 3 &&
    user.search('@') === -1 &&
    domain.search('@') === -1 &&
    user.search(' ') === -1 &&
    domain.search(' ') === -1 &&
    domain.search('.') !== -1 &&
    domain.indexOf('.') >= 1 &&
    domain.lastIndexOf('.') < domain.length - 1
  ) {
    return true
  }

  return false
}
