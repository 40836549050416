import { styled, Typography } from '@mui/material'
import { ReactComponent as IconLogOut } from 'assets/icons/log-out.svg'

export const PopoverTriggerContent = styled('div', { name: 'PopoverTriggerContent' })({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})

export const UserIcon = styled('div', { name: 'UserIcon' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#1C211F',
  backgroundColor: '#0CAC6E26',
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  fontWeight: '500'
})

export const UserIconText = styled(Typography, { name: 'UserIconText' })({
  fontWeight: '500'
})

export const StyledIconLogOut = styled(IconLogOut, { name: 'StyledIconLogOut' })({
  marginRight: '13px'
})
