import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

type PasswordInputProps = TextFieldProps

export default function PasswordInput(props: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>()

  return (
    <TextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? (
                <Visibility color="success" fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
