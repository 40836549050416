import { styled } from '@mui/material'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer)({
  '&&&.Toastify__toast-container': {
    '.Toastify__toast-icon': {
      marginInlineEnd: '17px',
      color: '#44bc4f'
    },
    '.Toastify__toast-body': {
      color: '#000',
      fontSize: '14px'
    },
    '.Toastify__close-button': {
      opacity: 1,
      alignSelf: 'center'
    }
  }
})
