import { Box, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import usePasswordRequestContext from 'modules/passwordRequest/hooks/usePasswordRequestContext'
import useValidatePasswordRequestContext from 'modules/passwordRequest/hooks/useValidatePasswordRequestContext'
import { useEffect } from 'react'
import VerificationCodeForm from './components/VerificationCodeForm'

export default function VerificationCode() {
  const { validatePasswordRequestContextEmail } = useValidatePasswordRequestContext()
  const { t } = useTranslation()
  const {
    email: [email]
  } = usePasswordRequestContext()

  useEffect(() => {
    validatePasswordRequestContextEmail()
  }, [validatePasswordRequestContextEmail])

  return (
    <Box>
      <Box
        marginBottom="35px"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            textAlign: 'center'
          }
        })}
      >
        <Typography variant="body2">
          {t('Enviamos um código de confirmação para o e-mail {{email}}.', {
            email: email
          })}
        </Typography>
      </Box>

      <VerificationCodeForm />
    </Box>
  )
}
