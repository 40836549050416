import { redirectUrl } from '@1eg/lib-core-web-components-js'
import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useIsMutating, useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useRegisterParser } from '../parsers/useRegisterParser'
import { useIamManagerRegistration } from '../useIamManagerRegistration'
import { RegisterFormType } from 'views/register/types/RegisterFormType'

export type UseRegisterOptions = UseMutationOptions<string, AxiosError, RegisterFormType>

export const useRegister = (
  options?: UseRegisterOptions
): UseMutationResult<string, AxiosError, RegisterFormType> => {
  const { endpoint, register } = useIamManagerRegistration()

  const { parseRegister } = useRegisterParser()

  const mutationKey = useMemo(() => [endpoint], [endpoint])

  const mutationFn = useCallback(
    async (RegisterFormType: RegisterFormType) => {
      const dto = parseRegister(RegisterFormType)

      const response = await register(dto)

      return response.data.token
    },
    [register, parseRegister]
  )

  const mutation = useMutation(mutationKey, mutationFn, {
    ...options,
    onSuccess: token => {
      window.location.href = redirectUrl('admin', {
        queryParams: { token }
      })
    }
  })

  return mutation
}

export const useRegistering = () => {
  const { endpoint } = useIamManagerRegistration()

  const mutationKey = useMemo(() => [endpoint], [endpoint])

  return !!useIsMutating(mutationKey)
}
