import { useContext } from 'react'
import ManagerTokenInviteContext, {
  ManagerTokenInviteContextStore
} from 'views/invite/contexts/ManagerTokenInviteContext'

const useManagerTokenInviteContext = () => {
  const managerTokenInviteContextData = useContext<ManagerTokenInviteContextStore | undefined>(
    ManagerTokenInviteContext
  )

  if (!managerTokenInviteContextData)
    throw new Error(
      'useManagerTokenInviteContext hook must be used inside ManagerTokenInviteProvider.'
    )

  return managerTokenInviteContextData
}

export default useManagerTokenInviteContext
