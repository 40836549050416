import { joiResolver } from '@hookform/resolvers/joi'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import ButtonLink from 'modules/button/components/ButtonLink'
import FormAlertError from 'modules/form/components/FormAlertError'
import FormHelperTextError from 'modules/input/components/FormHelperTextError'
import PasswordInput from 'modules/input/components/PasswordInput'
import { useCallback, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { LoginFormType } from 'views/login/types/LoginFormType'
import { isValidEmail } from 'utils'
import usePasswordRequestContext from 'modules/passwordRequest/hooks/usePasswordRequestContext'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import { useLoggingIn, useLogin } from 'services/api/iam/manager/mutations/useLogin'
import { TokenTypeEnum } from 'services/api/iam/manager/enums/TokenTypeEnum'

export default function LoginForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    email: [, setEmail],
    errorRequestPassword: [errorRequestPassword]
  } = usePasswordRequestContext()
  const {
    token: [, setToken]
  } = useAuthenticationContext()
  const {
    mutateAsync,
    error: errorLogin,
    isError: isErrorLogin
  } = useLogin({
    onSuccess: data => {
      setToken(data)

      switch (data.type) {
        case TokenTypeEnum.AUTH: {
          navigate('/choose-account')
          break
        }
        case TokenTypeEnum.TWO_FACTOR_AUTH:
          navigate('/2fa')
          break
      }
    }
  })
  const isLoading = useLoggingIn()

  const { control, getValues, setError, handleSubmit } = useForm<LoginFormType>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: joiResolver(
      Joi.object({
        email: Joi.string(),
        password: Joi.string()
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const alertError = useMemo(() => {
    return isErrorLogin || errorRequestPassword
  }, [isErrorLogin, errorRequestPassword])

  const alertErrorMessage = useMemo(() => {
    if (isErrorLogin) {
      return t(errorLogin.response?.data.code || errorLogin.message)
    }

    if (errorRequestPassword) {
      return t(errorRequestPassword)
    }

    return ''
  }, [isErrorLogin, t, errorRequestPassword, errorLogin])

  const goToRegisterNewPassword = useCallback(() => {
    const userEmail = getValues('email')

    if (!userEmail) {
      setError('email', { type: 'error', message: t('Campo de preenchimento obrigatório') })

      return
    }

    if (!isValidEmail(userEmail)) {
      setError('email', { type: 'error', message: t('E-mail inválido') })

      return
    }

    setEmail(userEmail)
    navigate('/register-new-password')
  }, [navigate, getValues, setEmail, setError, t])

  const submitHandler = useCallback<SubmitHandler<LoginFormType>>(
    data => {
      mutateAsync({
        username: data.email,
        password: data.password
      })
    },
    [mutateAsync]
  )

  return (
    <Box>
      <Stack gap="20px">
        {alertError && <FormAlertError message={alertErrorMessage} />}

        <form>
          <Stack gap="30px">
            <Controller
              control={control}
              name="email"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('E-mail')}</FormLabel>
                  <TextField
                    sx={{
                      input: {
                        '&:-webkit-autofill': {
                          '-webkit-box-shadow': '0 0 0 30px white inset'
                        }
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite seu e-mail')}
                    helperText={
                      <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                    }
                  />
                </FormControl>
              )}
            />

            <Stack gap="10px" alignItems="flex-start">
              <Controller
                control={control}
                name="password"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <FormControl required>
                    <FormLabel>{t('Senha')}</FormLabel>
                    <PasswordInput
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      placeholder={t('Digite sua senha')}
                      helperText={
                        <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                      }
                      sx={{
                        input: {
                          '&:-webkit-autofill': {
                            '-webkit-box-shadow': '0 0 0 30px white inset'
                          }
                        }
                      }}
                    />
                  </FormControl>
                )}
              />

              <ButtonLink onClick={() => goToRegisterNewPassword()}>
                {t('Esqueceu sua senha?')}
              </ButtonLink>
            </Stack>

            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              loading={isLoading}
              sx={{ marginTop: '10px' }}
              onClick={handleSubmit(submitHandler)}
            >
              {t('Entrar')}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Box>
  )
}
