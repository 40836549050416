import { useCallback } from 'react'
import { RecoverPasswordRequest } from '../interfaces/RecoverPasswordRequest'
import { UseRecoverPasswordMutationParams } from '../mutations/useRecoverPassword'

export const useRecoverPasswordParser = () => {
  const parse = useCallback<(domain: UseRecoverPasswordMutationParams) => RecoverPasswordRequest>(
    (domain: UseRecoverPasswordMutationParams) => ({
      email: domain.email,
      password: domain.password
    }),
    []
  )

  return { parse }
}
