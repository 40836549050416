import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import RegisterFormTitle from './components/RegisterFormTitle'
import ButtonLink from 'modules/button/components/ButtonLink'
import { useNavigate } from 'react-router'
import { CookiesSnackbar } from 'modules/cookies/components/CookiesSnackbar'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import RegisterForm from './components/RegisterForm'

export default function Register() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box>
      <RegisterFormTitle />

      <GTMProvider
        state={{ id: 'GTM-K5B8SR4F', injectScript: !!process.env.REACT_APP_REGISTER_GTM }}
      >
        <RegisterForm />
      </GTMProvider>

      <Stack direction="row" gap="5px" justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <Typography variant="body2">{t('Já possui conta?')}</Typography>
        <ButtonLink onClick={() => navigate('/')}>{t('Faça login')}</ButtonLink>
      </Stack>

      <CookiesSnackbar />
    </Box>
  )
}
