import { SixDigitsCodeInput, SixDigitsCodeInputRef } from '@emerald-ui/inputs'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { FormHelperText, Stack, Typography } from '@mui/material'
import ButtonContained from 'components/buttons/buttonContained/ButtonContained'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthenticate2FA } from 'services/api/iam/manager/mutations/useAuthenticate2FA'

export default function TwoFactorAuth() {
  const navigate = useNavigate()

  const sixDigitsCodeInputRef = useRef<SixDigitsCodeInputRef>(null)

  const [invalidCode, setInvalidCode] = useState(false)

  const [code, setCode] = useState<Array<string>>(Array(6).fill(''))

  const {
    token: [token, setToken]
  } = useAuthenticationContext()

  const { mutate, isLoading } = useAuthenticate2FA({
    onSuccess(data) {
      setToken(data)
      navigate('/choose-account')
    },
    onError() {
      setInvalidCode(true)
    }
  })

  const handleSubmit = useCallback(() => {
    if (code.some(value => !value) || !token?.token || isLoading) return
    mutate({ token: token.token, sixDigitsCode: code.join('') })
  }, [mutate, code, token, isLoading])

  useEffect(() => {
    if (invalidCode) setTimeout(() => sixDigitsCodeInputRef.current?.focus(), 100)
  }, [invalidCode])

  useEffect(() => {
    setTimeout(() => sixDigitsCodeInputRef.current?.focus(), 0)
  }, [])

  useEffect(() => {
    if (!token) navigate('/')
  }, [token, navigate])

  return (
    <Stack gap={11.25}>
      <Stack gap={1.25}>
        <Typography variant="h4">Verificação em 2 etapas</Typography>
        <Typography variant="subtitle1">Insira o código para acessar o painel</Typography>
      </Stack>
      <Stack gap={3.75}>
        <Stack gap={2.5}>
          <Typography variant="subtitle2">
            Insira o código gerado pelo aplicativo de autenticação
          </Typography>
          <Stack gap={1}>
            <SixDigitsCodeInput
              ref={sixDigitsCodeInputRef}
              error={invalidCode}
              disabled={isLoading}
              onKeyDown={evt => evt.key === 'Enter' && handleSubmit()}
              onChange={(_, value) => {
                setInvalidCode(false)
                setCode(value)
              }}
            />
            {invalidCode ? (
              <FormHelperText
                error
                component={Stack}
                gap={0.5}
                direction="row"
                alignItems="center"
                alignSelf="flex-start"
              >
                <InfoOutlinedIcon color="error" fontSize="small" />O código digitado não é válido
              </FormHelperText>
            ) : null}
          </Stack>
        </Stack>
        <Stack gap={2.5}>
          <ButtonContained
            loading={isLoading}
            onClick={() => handleSubmit()}
            disabled={code.some(value => !value) || isLoading}
            variant="contained"
          >
            Continuar
          </ButtonContained>
          <Stack
            direction="row"
            alignItems="center"
            gap={1.25}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <ArrowBackRoundedIcon
              sx={theme => ({ color: theme.palette.text.secondary })}
              fontSize="small"
            />
            <Typography color="text.secondary">Voltar</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
