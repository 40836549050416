import PasswordRequestContext, {
  PasswordRequestContextStore
} from 'modules/passwordRequest/contexts'
import { useContext } from 'react'

const usePasswordRequestContext = () => {
  const passwordRequestContextData = useContext<PasswordRequestContextStore | undefined>(
    PasswordRequestContext
  )

  if (!passwordRequestContextData)
    throw new Error(
      'usePasswordRequestContext hook must be used inside PasswordRequestContextProvider.'
    )

  return passwordRequestContextData
}

export default usePasswordRequestContext
