import { FormHelperText, Stack } from '@mui/material'
import { PropsWithChildren } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

type FormHelperTextErrorProps = {
  error: boolean
}

export default function FormHelperTextError(props: PropsWithChildren<FormHelperTextErrorProps>) {
  const { error, children } = props
  return error ? (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <ErrorOutlineIcon color="error" fontSize="small" />
      <FormHelperText error>{children}</FormHelperText>
    </Stack>
  ) : null
}
