import { useCallback } from 'react'

type ResizeDimension = number | 'orig' | undefined

type ProcessOptions = {
  resize?: {
    fitIn?: boolean
    width?: ResizeDimension
    height?: ResizeDimension
  }
  filters?: {
    fill?: string
  }
}

export default function useImageProcessor() {
  const buildImsSearchParameter = useCallback((options: ProcessOptions): string => {
    let parameter: string[] = []

    if (options.resize) {
      if (options.resize.fitIn) parameter.push('fit-in')
      if (options.resize.width !== undefined || options.resize.height !== undefined) {
        let dimensions: ResizeDimension[] = [options.resize.width, options.resize.height]
        parameter.push(dimensions.join('x'))
      }
    }

    if (options.filters && !!Object.keys(options.filters).length) {
      const filters: string[] = Object.entries(options.filters).map(
        ([key, value]) => `${key}(${value})`
      )

      parameter.push(`filters:${filters.join(':')}`)
    }

    return parameter.join('/')
  }, [])

  const process = useCallback(
    (urn: string, options: ProcessOptions): string => {
      try {
        const url = new URL(urn)

        url.searchParams.set('ims', buildImsSearchParameter(options))

        return decodeURIComponent(url.href)
      } catch {
        return urn
      }
    },
    [buildImsSearchParameter]
  )

  return {
    process
  }
}
