import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useIsMutating, useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { LoginRequest } from '../interfaces/LoginRequest'
import { useIamManagerAuthentication } from '../useIamManagerAuthentication'
import { LoginResponse } from '../interfaces/LoginResponse'

export type UseLoginOptions = UseMutationOptions<LoginResponse, AxiosError, LoginRequest>

export const useLogin = (
  options?: UseLoginOptions
): UseMutationResult<LoginResponse, AxiosError, LoginRequest> => {
  const { endpoint, login } = useIamManagerAuthentication()
  const mutationKey = useMemo(() => [endpoint], [endpoint])

  const mutationFn = useCallback(
    async (auth: LoginRequest) => {
      const response = await login(auth)

      return response.data
    },
    [login]
  )

  const mutation = useMutation<LoginResponse, AxiosError, LoginRequest>({
    ...options,
    mutationKey,
    mutationFn
  })

  return mutation
}

export const useLoggingIn = () => {
  const { endpoint } = useIamManagerAuthentication()

  const mutationKey = useMemo(() => [endpoint], [endpoint])

  return !!useIsMutating(mutationKey)
}
