import Popover from 'components/popover/components/Popover'
import PopoverContent from 'components/popover/components/PopoverContent'
import { Fade, MenuList, MenuItem, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { PopoverTriggerContent, UserIcon, UserIconText, StyledIconLogOut } from './Styles'
import PopoverTrigger from 'components/popover/components/PopoverTrigger'
import { ReactComponent as IconChevronDown } from 'assets/icons/chevron-down.svg'
import useDecodedToken from 'modules/authentication/hooks/useDecodedToken'
import { useLogoutUser } from 'services/api/iam/manager/mutations/useLogoutUser'

type UserMenuProps = {}

const UserMenu: FC<UserMenuProps> = () => {
  const {
    user: { name }
  } = useDecodedToken()

  const userInitialLetters = useMemo(() => {
    if (!name) return ''

    const splittedName = name?.trim().toLocaleUpperCase().split(' ') || []

    const { 0: firstName, [splittedName.length - 1]: lastName } = splittedName

    if (splittedName.length === 1) {
      return firstName[0]
    }

    return firstName[0] + lastName[0]
  }, [name])
  const { mutateAsync: logoutUser } = useLogoutUser()

  return (
    <Popover triggerMode="hover">
      <PopoverTrigger>
        <PopoverTriggerContent>
          <UserIcon>
            <UserIconText variant="body2">{userInitialLetters}</UserIconText>
          </UserIcon>

          <IconChevronDown />
        </PopoverTriggerContent>

        <PopoverContent
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          TransitionComponent={Fade}
          PaperProps={{
            sx: {
              boxShadow: '0px 3px 6px #00000014',
              borderRadius: 2,
              marginTop: 2,
              border: '1px solid #E2E7E5'
            }
          }}
        >
          <MenuList autoFocus variant="menu" sx={{ width: 257, outline: '0 !important' }}>
            <MenuItem onClick={() => logoutUser()}>
              <StyledIconLogOut />
              <Typography variant="body2">Sair</Typography>
            </MenuItem>
          </MenuList>
        </PopoverContent>
      </PopoverTrigger>
    </Popover>
  )
}

export default UserMenu
