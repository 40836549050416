import { createContext } from 'react'
import { InviteDecodedTokenType } from '../types/InviteDecodedTokenType'

export type ManagerTokenInviteContextStore = {
  token: string
  decodedToken: InviteDecodedTokenType | undefined
}

const ManagerTokenInviteContext = createContext<ManagerTokenInviteContextStore | undefined>(
  undefined
)

export default ManagerTokenInviteContext
