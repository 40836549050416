import { PropsWithChildren, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ManagerTokenInviteContext from 'views/invite/contexts/ManagerTokenInviteContext'
import { InviteDecodedTokenType } from 'views/invite/types/InviteDecodedTokenType'
import decode from 'jwt-decode'

type ManagerTokenInviteProviderProps = {}

export default function ManagerTokenInviteProvider(
  props: PropsWithChildren<ManagerTokenInviteProviderProps>
) {
  const { children } = props
  const [token, setToken] = useState<string>('')
  const [decodedToken, setDecodedToken] = useState<InviteDecodedTokenType | undefined>()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const tokenParam = searchParams.get('token')

    if (tokenParam) {
      setToken(tokenParam)
      setDecodedToken(decode<InviteDecodedTokenType>(tokenParam))
    } else {
      navigate('/')
    }
  }, [token, navigate, searchParams])

  return (
    <ManagerTokenInviteContext.Provider value={{ token, decodedToken }}>
      {children}
    </ManagerTokenInviteContext.Provider>
  )
}
