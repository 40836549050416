import { Stack, Typography, styled } from '@mui/material'
import { useMemo } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ReactComponent as IconCheck } from 'assets/icons/check.svg'

type PasswordInputFormHelperTextErrorProps = {
  error: boolean
  value: string | null
}

const HighlightMessage = styled('span', {
  name: 'HighlightMessage',
  shouldForwardProp: prop => prop !== 'error' && prop !== 'value'
})<{ error: boolean; value: string | null }>(({ theme, error, value }) => ({
  color: error
    ? '#FF496A'
    : !value && !error
    ? theme.palette.text.secondary
    : theme.palette.primary.main
}))

export default function PasswordInputFormHelperTextError(
  props: PasswordInputFormHelperTextErrorProps
) {
  const { error, value } = props
  const patternsValidate = useMemo(
    () => ({
      characters: /^.{8,}$/,
      upperCase: /[A-Z]/,
      lowerCase: /[a-z]/,
      number: /[0-9]/,
      specialChar: /[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`]/
    }),
    []
  )

  const hasCharactersError = useMemo(
    () => value && !patternsValidate.characters.test(value),
    [value, patternsValidate.characters]
  )

  const hasUpperCaseError = useMemo(
    () => value && !patternsValidate.upperCase.test(value),
    [value, patternsValidate.upperCase]
  )

  const hasLowerCaseError = useMemo(
    () => value && !patternsValidate.lowerCase.test(value),
    [value, patternsValidate.lowerCase]
  )

  const hasNumberError = useMemo(
    () => value && !patternsValidate.number.test(value),
    [value, patternsValidate.number]
  )

  const hasSpecialCharError = useMemo(
    () => value && !patternsValidate.specialChar.test(value),
    [value, patternsValidate.specialChar]
  )

  const noError = useMemo(
    () =>
      !error &&
      [
        !hasCharactersError,
        !hasUpperCaseError,
        !hasLowerCaseError,
        !hasNumberError,
        !hasSpecialCharError
      ].every(item => item),
    [
      hasCharactersError,
      hasUpperCaseError,
      hasLowerCaseError,
      hasNumberError,
      hasSpecialCharError,
      error
    ]
  )
  return (
    <Stack direction="row" spacing="10px" marginTop="6px">
      {(value || error) && (
        <Stack>
          {noError ? <IconCheck /> : <ErrorOutlineIcon color="error" fontSize="small" />}
        </Stack>
      )}

      <Stack>
        <Typography fontSize="11px" color="text.secondary">
          Mínimo de{' '}
          <HighlightMessage error={!!hasCharactersError || (!!!value && !!error)} value={value}>
            8 caracteres
          </HighlightMessage>
          , contendo um{' '}
          <HighlightMessage error={!!hasNumberError || (!!!value && !!error)} value={value}>
            número
          </HighlightMessage>
          , uma{' '}
          <HighlightMessage error={!!hasUpperCaseError || (!!!value && !!error)} value={value}>
            letra maiúscula
          </HighlightMessage>
          ,{' '}
          <HighlightMessage error={!!hasLowerCaseError || (!!!value && !!error)} value={value}>
            letra minúscula
          </HighlightMessage>{' '}
          e{' '}
          <HighlightMessage error={!!hasSpecialCharError || (!!!value && !!error)} value={value}>
            caracteres especiais @#/*.
          </HighlightMessage>
        </Typography>
      </Stack>
    </Stack>
  )
}
