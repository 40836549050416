import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useRecoverPasswordParser } from '../parsers/useRecoverPasswordParser'
import { useIamManagerPassword } from '../useIamManagerPassword'

export type UseRecoverPasswordMutationParams = {
  password: string
  repeatedPassword?: string
  email: string
}

export type UseRecoverPasswordOptions = UseMutationOptions<
  void,
  AxiosError,
  UseRecoverPasswordMutationParams
>

export const useRecoverPassword = (
  options?: UseRecoverPasswordOptions
): UseMutationResult<void, AxiosError, UseRecoverPasswordMutationParams> => {
  const { endpoint, recoverPassword } = useIamManagerPassword()
  const mutationKey = useMemo(() => [endpoint], [endpoint])
  const { parse } = useRecoverPasswordParser()
  const navigate = useNavigate()

  const mutationFn = useCallback(
    async (body: UseRecoverPasswordMutationParams) => {
      await recoverPassword(parse(body))
    },
    [recoverPassword, parse]
  )

  const mutation = useMutation<void, AxiosError, UseRecoverPasswordMutationParams>(
    mutationKey,
    mutationFn,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        navigate('/verification-code')
      }
    }
  )

  return mutation
}
