import { Link, SnackbarContent, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import { useState } from 'react'

export function CookiesSnackbar(props: SnackbarProps) {
  const [open, setOpen] = useState(true)

  const handleClose = () => setOpen(false)

  return (
    <Snackbar
      {...props}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{ width: 341, '& .MuiSnackbarContent-message': { padding: 0 }, ...props.sx }}
      open={open}
    >
      <SnackbarContent
        sx={{
          background: '#fff',
          padding: '30px'
        }}
        message={
          <Stack spacing={2}>
            <Typography fontWeight="bold">Gerenciar consentimento</Typography>

            <Typography variant="caption">
              Usamos cookies para melhorar sua experiência. Ao continuar, você concorda com o uso de
              cookies. Saiba mais em nossa{' '}
              <Link href="https://ecomece.com.br/politica-de-cookies-br/" target="_blank">
                Política de Cookies
              </Link>
              .
            </Typography>

            <Button
              onClick={handleClose}
              sx={{ fontWeight: 500, color: '#fff' }}
              variant="contained"
              fullWidth
            >
              Aceitar
            </Button>
          </Stack>
        }
      />
    </Snackbar>
  )
}
