import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useIsMutating, useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { AcceptInviteParams } from '../interfaces/AcceptInviteParams'
import { useIamManagerInvite } from '../useIamManagerInvite'
import { useNavigate } from 'react-router-dom'
import { toastSuccess, toastError } from 'utils/toast/Toast'
import { useTranslation } from 'hooks/useTranslation'

export type UseAcceptInviteMutationOptions = UseMutationOptions<
  void,
  AxiosError,
  AcceptInviteParams
>

export const useAcceptInvite = (
  options?: UseAcceptInviteMutationOptions
): UseMutationResult<void, AxiosError, AcceptInviteParams> => {
  const { endpoint, acceptInvite } = useIamManagerInvite()
  const mutationKey = useMemo(() => [endpoint], [endpoint])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mutationFn = useCallback(
    async (params: AcceptInviteParams) => {
      await acceptInvite(params)
    },
    [acceptInvite]
  )

  const mutation = useMutation<void, AxiosError, AcceptInviteParams>(mutationKey, mutationFn, {
    ...options,
    onSuccess: () => {
      navigate('/')
      toastSuccess(t('Registro realizado com sucesso!'))
    },
    onError: () => {
      toastError(t('Erro ao realizar registro!'))
    }
  })

  return mutation
}

export const useAcceptingInvite = () => {
  const { endpoint } = useIamManagerInvite()

  const mutationKey = useMemo(() => [endpoint], [endpoint])

  return !!useIsMutating(mutationKey)
}
