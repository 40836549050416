import { StringMap, TOptions, TOptionsBase } from 'i18next'
import { useTranslation as i18nHook } from 'react-i18next'

export enum TranslationNamespace {
  LOGIN = 'login'
}

export const useTranslation = <N extends TranslationNamespace>(...namespace: N[]) => {
  const { t: tHook, i18n } = i18nHook<string[]>(['translation', ...namespace])

  const t = (
    key: string,
    options?: string | TOptions<StringMap> | TOptionsBase,
    nsDefault?: boolean | N
  ) => {
    if (!nsDefault && namespace.length) return tHook(`${namespace[0]}:${key}`, options)

    if (typeof nsDefault !== 'boolean' && nsDefault) return tHook(`${nsDefault}:${key}`, options)

    return tHook(key, options)
  }

  const tDefault = (key: string, options?: string | TOptions<StringMap> | TOptionsBase) =>
    t(key, options, true)

  return {
    t,
    tDefault,
    i18n
  }
}
