import { toast } from 'react-toastify'
import { ReactComponent as IconCheckCircle } from 'assets/icons/check-circle.svg'

export function toastSuccess(message: string) {
  return toast.success(message, {
    icon: () => <IconCheckCircle />
  })
}

export function toastError(message: string) {
  return toast.error(message)
}

export function toastWarn(message: string) {
  return toast.warn(message)
}

export function toastInfo(message: string) {
  return toast.info(message)
}
