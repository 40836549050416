import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useIsMutating, useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { RefuseInviteParams } from '../interfaces/RefuseInviteParams'
import { useIamManagerInvite } from '../useIamManagerInvite'
import { useNavigate } from 'react-router-dom'
import { toastSuccess, toastError } from 'utils/toast/Toast'
import { useTranslation } from 'hooks/useTranslation'

export type UseRefuseInviteMutationOptions = UseMutationOptions<
  void,
  AxiosError,
  RefuseInviteParams
>

export const useRefuseInvite = (
  options?: UseRefuseInviteMutationOptions
): UseMutationResult<void, AxiosError, RefuseInviteParams> => {
  const { endpoint, refuseInvite } = useIamManagerInvite()
  const mutationKey = useMemo(() => [endpoint], [endpoint])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mutationFn = useCallback(
    async (params: RefuseInviteParams) => {
      await refuseInvite(params)
    },
    [refuseInvite]
  )

  const mutation = useMutation<void, AxiosError, RefuseInviteParams>(mutationKey, mutationFn, {
    ...options,
    onSuccess: () => {
      navigate('/')
      toastSuccess(t('Convite recusado com sucesso!'))
    },
    onError: () => {
      toastError(t('Erro ao recusar convite!'))
    }
  })

  return mutation
}

export const useRefusingInvite = () => {
  const { endpoint } = useIamManagerInvite()

  const mutationKey = useMemo(() => [endpoint], [endpoint])

  return !!useIsMutating(mutationKey)
}
