import { FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import AuthenticationErrorBoundaryFallback from '../AuthenticationErrorBoundaryFallback'

type AuthenticationErrorBoundaryProps = {}

const AuthenticationErrorBoundary: FC<AuthenticationErrorBoundaryProps> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={AuthenticationErrorBoundaryFallback}>
      {children}
    </ErrorBoundary>
  )
}

export default AuthenticationErrorBoundary
