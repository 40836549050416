import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import useDecodedToken from 'modules/authentication/hooks/useDecodedToken'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconNavigateBack } from 'assets/icons/navegation-back.svg'
import CreateAccountForm from './components/CreateAccountForm'

export default function CreateAccount() {
  useDecodedToken()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box>
      <Stack flexDirection="row" alignItems="center" gap="10px" marginBottom="40px">
        <Box
          onClick={() => navigate(-1)}
          sx={{ cursor: 'pointer' }}
          display="flex"
          alignItems="center"
        >
          <IconNavigateBack />
        </Box>

        <Typography variant="h6">{t('Crie uma nova loja')}</Typography>
      </Stack>

      <CreateAccountForm />
    </Box>
  )
}
