import { useExtendSx } from '@emerald-ui/theme'
import { Button, ButtonProps, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

type ButtonLinkProps = ButtonProps

export default function ButtonLink(props: PropsWithChildren<ButtonLinkProps>) {
  const { children, onClick, sx } = props
  const extendSx = useExtendSx()
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      sx={extendSx(
        {
          fontSize: '14px',
          fontWeight: '400',
          textDecoration: 'underline',
          padding: 0,
          '&:hover': {
            textDecoration: 'underline',
            color: '#098757'
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '12px'
          }
        },
        { ...sx }
      )}
    >
      {children}
    </Button>
  )
}
