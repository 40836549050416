import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { SalesInformationGetResponse } from './interfaces/SalesInformationGetResponse'

export const useAisPartnerSalesInformation = () => {
  const endpoint = 'ais-partner/sales-information'

  const get = useCallback(
    async (resellerSlug: string) =>
      (await axiosInstance.get<SalesInformationGetResponse>(`/${endpoint}/${resellerSlug}`)).data,
    []
  )

  return {
    get,
    endpoint
  }
}
