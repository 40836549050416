import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { RecoverPasswordRequest } from './interfaces/RecoverPasswordRequest'
import { VerifyCodeRecoverPasswordRequest } from './interfaces/VerifyCodeRecoverPasswordRequest'

export const useIamManagerPassword = () => {
  const endpoint = 'iam-manager/password'

  const recoverPassword = useCallback(
    async (body: RecoverPasswordRequest) =>
      axiosInstance.post(`${endpoint}/request`, { ...body, roleType: 'MANAGER' }),
    []
  )

  const verifyCodeRecoverPassword = useCallback(
    async (body: VerifyCodeRecoverPasswordRequest) =>
      axiosInstance.post(`${endpoint}/verify`, body),
    []
  )

  return {
    endpoint,
    recoverPassword,
    verifyCodeRecoverPassword
  }
}
