import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  useRefuseInvite,
  useRefusingInvite
} from 'services/api/iam/manager/mutations/useRefuseInvite'
import useManagerTokenInviteContext from 'views/invite/hooks/useManagerTokenInviteContext'

type DeclineInvitationModalProps = {
  open: boolean
  handleClose: () => void
}

export default function DeclineInvitationModal(props: DeclineInvitationModalProps) {
  const { open, handleClose } = props
  const { t } = useTranslation()
  const { token, decodedToken } = useManagerTokenInviteContext()
  const { mutateAsync } = useRefuseInvite()
  const isLoading = useRefusingInvite()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('Recusar convite')}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#000'
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          {t('Tem certeza de que deseja recusar este convite?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          {t('Cancelar')}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={() => mutateAsync({ id: decodedToken ? decodedToken.id : '', token })}
          loading={isLoading}
        >
          {t('Recusar')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
