import { createContext, Dispatch, SetStateAction } from 'react'
import { TokenTypeEnum } from 'services/api/iam/manager/enums/TokenTypeEnum'

export type TokenData = {
  token: string
  type: TokenTypeEnum
}

export type AuthenticationContextStore = {
  token: [TokenData | undefined, Dispatch<SetStateAction<TokenData | undefined>>]
}

const AuthenticationContext = createContext<AuthenticationContextStore | undefined>(undefined)

export default AuthenticationContext
