import { FC, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type ErrorBoundaryFallbackProps = {}

const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = props => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return <Fragment />
}

export default ErrorBoundaryFallback
