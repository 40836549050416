import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { ErrorResponse } from 'services/base/interfaces/ErrorResponse'
import { SalesInformationGetResponse } from '../interfaces/SalesInformationGetResponse'
import { useAisPartnerSalesInformation } from '../useAisPartnerSalesInformation'

type Data = SalesInformationGetResponse
type Error = AxiosError<ErrorResponse>
type UseGetSalesInformationByResellerSlugOptions = UseQueryOptions<Data, Error>
type UseGetSalesInformationByResellerSlugResult = UseQueryResult<Data, Error>

export default function useGetSalesInformationByResellerSlug(
  resellerSlug?: string,
  options?: UseGetSalesInformationByResellerSlugOptions
): UseGetSalesInformationByResellerSlugResult {
  const { endpoint, get } = useAisPartnerSalesInformation()

  const queryKey = useMemo(() => [endpoint, resellerSlug], [endpoint, resellerSlug])

  const queryFn = useCallback(() => get(resellerSlug!), [get, resellerSlug])

  return useQuery<Data, Error>(queryKey, queryFn, { ...options, enabled: !!resellerSlug })
}
