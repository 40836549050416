import { Token } from 'interfaces/Token'
import useAuthenticationContext from '../useAuthenticationContext'
import decode from 'jwt-decode'
import { useCallback } from 'react'

const useDecodedToken = (): Token => {
  const {
    token: [token]
  } = useAuthenticationContext()

  const getDecodedToken = useCallback(() => {
    if (!token) {
      throw new Error('Undefined token')
    }

    const decodedToken = decode<Token>(token.token)

    return decodedToken
  }, [token])

  return getDecodedToken()
}

export default useDecodedToken
