import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { RegisterRequestDTO } from './interfaces/RegisterRequestDTO'

export const useIamManagerRegistration = () => {
  const endpoint = 'iam-manager/registration'

  const register = useCallback(
    async (dto: RegisterRequestDTO) => axiosInstance.post(`${endpoint}/trial`, dto),
    []
  )

  return {
    endpoint,
    register
  }
}
