import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { AcceptInviteParams } from './interfaces/AcceptInviteParams'
import { GetInviteStatusParams } from './interfaces/GetInviteStatusParams'
import { GetInviteStatusResponse } from './interfaces/GetInviteStatusResponse'
import { RefuseInviteParams } from './interfaces/RefuseInviteParams'

export const useIamManagerInvite = () => {
  const endpoint = 'iam-manager/invite'

  const getInviteStatus = useCallback(
    async (params: GetInviteStatusParams) =>
      axiosInstance.get<GetInviteStatusResponse>(`${endpoint}/${params.id}/status`, {
        headers: { Authorization: `Bearer ${params.token}` }
      }),
    []
  )

  const acceptInvite = useCallback(
    async (params: AcceptInviteParams) =>
      axiosInstance.put(`${endpoint}/${params.id}/accept`, params.body, {
        headers: { Authorization: `Bearer ${params.token}` }
      }),
    []
  )

  const refuseInvite = useCallback(
    async (params: RefuseInviteParams) =>
      axiosInstance.put(`${endpoint}/${params.id}/refuse`, undefined, {
        headers: { Authorization: `Bearer ${params.token}` }
      }),
    []
  )

  return {
    endpoint,
    getInviteStatus,
    refuseInvite,
    acceptInvite
  }
}
