import { ThemeProvider } from '@emerald-ui/theme'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { Layout } from 'components/layout/Layout'
import queryClient from 'configs/queryClient'
import AuthenticationProvider from 'modules/authentication/providers/AuthenticationProvider'
import AppLoader from 'modules/loader/components/AppLoader/AppLoader'
import { Suspense } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { StyledToastContainer } from 'utils/toast/Styles'

const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <GlobalStyles styles={{ body: { overflow: 'hidden' } }} />
        <ThemeProvider>
          <Suspense fallback={<AppLoader />}>
            <BrowserRouter>
              <AuthenticationProvider>
                <Layout />
              </AuthenticationProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
        <StyledToastContainer pauseOnHover={false} hideProgressBar />
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </QueryClientProvider>
    </div>
  )
}

export default App
