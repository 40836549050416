import type { RouteObject } from 'react-router-dom'
import Login from 'views/login'
import ChooseAccount from 'views/chooseAccount'
import AuthenticationErrorBoundary from 'modules/authentication/components/AuthenticationErrorBoundary/AuthenticationErrorBoundary'
import ErrorBoundaryComponent from 'modules/errorBoundary/components/ErrorBoundaryComponent'
import Register from 'views/register'
import Invite from 'views/invite'
import TwoFactorAuth from 'views/twoFactorAuth/TwoFactorAuth'
import CreateAccount from 'views/createAccount'
import ManagerTokenInviteProvider from 'views/invite/providers/ManagerTokenInviteProvider'
import RegisterNewPassword from 'views/registerNewPassword/RegisterNewPassword'
import VerificationCode from 'views/verificationCode/VerificationCode'

export const routes: RouteObject[] = [
  {
    path: '*',
    element: <Login />
  },
  {
    path: '/choose-account',
    element: (
      <AuthenticationErrorBoundary>
        <ChooseAccount />
      </AuthenticationErrorBoundary>
    )
  },
  {
    path: '/create-account',
    element: (
      <AuthenticationErrorBoundary>
        <CreateAccount />
      </AuthenticationErrorBoundary>
    )
  },
  {
    path: '/register-new-password',
    element: (
      <ErrorBoundaryComponent>
        <RegisterNewPassword />
      </ErrorBoundaryComponent>
    )
  },
  {
    path: '/verification-code',
    element: (
      <ErrorBoundaryComponent>
        <VerificationCode />
      </ErrorBoundaryComponent>
    )
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/invite',
    element: (
      <ErrorBoundaryComponent>
        <ManagerTokenInviteProvider>
          <Invite />
        </ManagerTokenInviteProvider>
      </ErrorBoundaryComponent>
    )
  },
  {
    path: '/2fa',
    element: (
      <ErrorBoundaryComponent>
        <TwoFactorAuth />
      </ErrorBoundaryComponent>
    )
  }
]
