import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material'
import { useMemo, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'hooks/useTranslation'
import FormAlertError from 'modules/form/components/FormAlertError'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import { useGetMe } from 'services/api/iam/manager/queries/useGetMe'
import { useLoginWithSelectedAccount } from 'services/api/iam/manager/mutations/useLoginWithSelectedAccount'
import StarIcon from '@mui/icons-material/Star'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export default function ChooseAccountList() {
  const { t } = useTranslation()
  const [searchInputValue, setSearchInputValue] = useState<string>('')
  const [isLoadingSelectedAccount, setIsLoadingSelectedAccount] = useState<boolean>()
  const {
    token: [token]
  } = useAuthenticationContext()
  const {
    data,
    isLoading: isLoadingGeByIdtUser,
    isError: isErrorGetByIdUser
  } = useGetMe(token?.token)
  const { mutateAsync: selectedAccount, isError: isErrorLoginWithSelectedAccount } =
    useLoginWithSelectedAccount({
      onError: () => {
        setIsLoadingSelectedAccount(false)
      }
    })

  const isLoading = useMemo(() => {
    return isLoadingGeByIdtUser || isLoadingSelectedAccount
  }, [isLoadingGeByIdtUser, isLoadingSelectedAccount])

  const isError = useMemo(() => {
    return isErrorGetByIdUser || isErrorLoginWithSelectedAccount
  }, [isErrorGetByIdUser, isErrorLoginWithSelectedAccount])

  const filterAccounts = useMemo(() => {
    return data?.accounts.filter(({ name }) =>
      name.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase())
    )
  }, [data?.accounts, searchInputValue])

  return (
    <Box>
      {isError && (
        <Box marginTop="30px">
          <FormAlertError
            message={t('Ops, um erro inesperado aconteceu. Tente novamente em instantes!')}
          />
        </Box>
      )}

      {isLoading && (
        <Box padding="150px" justifyContent="center" display="flex">
          <CircularProgress size={60} sx={[{ color: 'primary.main' }]} />
        </Box>
      )}

      {!isError && !isLoading && !!data?.accounts.length && (
        <Stack gap="30px">
          <OutlinedInput
            sx={{ borderRadius: '3px' }}
            placeholder="Pesquisar nome da loja"
            value={searchInputValue}
            onChange={event => setSearchInputValue(event.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <IconButton
                  sx={{
                    backgroundColor: 'transparent',
                    padding: 0,
                    '&:hover': {
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: '#DFDFDF',
                      width: '20px',
                      height: '20px'
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              !!searchInputValue && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchInputValue('')}>
                    <CloseIcon
                      sx={{
                        color: '#6E857C',
                        width: '17px',
                        height: '17px'
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }
          />

          {filterAccounts?.length ? (
            <Stack gap="20px">
              {filterAccounts.map((account, index) => (
                <Stack
                  key={index}
                  direction="row"
                  justifyContent="space-between"
                  sx={theme => ({
                    width: '100%',
                    padding: '15px 17px',
                    borderRadius: '2px',
                    border: '1px solid #CCCCCC',
                    cursor: 'pointer',
                    transition: theme.transitions.create(['background-color', 'box-shadow']),
                    ':hover': {
                      backgroundColor: '#F4F5F5',
                      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                    }
                  })}
                  onClick={() => {
                    setIsLoadingSelectedAccount(true)
                    selectedAccount({ accountId: account.id, token: token?.token! })
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body2" fontWeight="fontWeightMedium" color="primary">
                      {account.name}
                    </Typography>

                    {account.favorite && <StarIcon fontSize="small" sx={{ color: '#21D59B' }} />}
                  </Stack>

                  <KeyboardArrowRightIcon color="secondary" fontSize="small" />
                </Stack>
              ))}
            </Stack>
          ) : (
            <Box padding="100px 0" display="flex" justifyContent="center">
              <Stack alignItems="center">
                <Typography variant="subtitle1" color="text.secondary">
                  Parece que não encontramos nada.
                </Typography>

                <Typography variant="subtitle1" color="text.secondary">
                  Que tal tentar uma nova busca?
                </Typography>
              </Stack>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  )
}
