import { FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from '../ErrorBoundaryFallback'

type ErrorBoundaryProps = {}

const ErrorBoundaryComponent: FC<ErrorBoundaryProps> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>{children}</ErrorBoundary>
}

export default ErrorBoundaryComponent
