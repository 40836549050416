import { Alert, Typography } from '@mui/material'

type FormAlertErrorProps = {
  message: string
}

export default function FormAlertError(props: FormAlertErrorProps) {
  const { message } = props

  return (
    <Alert
      severity="error"
      sx={theme => ({ border: `1px solid ${theme.palette.error.main}`, borderRadius: '3px' })}
    >
      <Typography variant="body2">
        {message}
      </Typography>
    </Alert>
  )
}
