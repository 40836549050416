import PasswordRequestContext from 'modules/passwordRequest/contexts/PasswordRequestContext'
import { FC, useState } from 'react'

const PasswordRequestProvider: FC = ({ children }) => {
  const email = useState<string>()
  const password = useState<string>()
  const errorRequestPassword = useState<string>()

  return (
    <PasswordRequestContext.Provider value={{ email, password, errorRequestPassword }}>
      {children}
    </PasswordRequestContext.Provider>
  )
}

export default PasswordRequestProvider
