import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import Cookies from 'js-cookie'
import { LoginWithSelectedAccountRequest } from '../interfaces/LoginWithSelectedAccountRequest'
import { useIamManagerAuthorization } from '../useIamManagerAuthorization'
import { redirectUrl } from '@1eg/lib-core-web-components-js'

export type UseLoginWithSelectedAccountOptions = UseMutationOptions<
  string,
  AxiosError,
  LoginWithSelectedAccountRequest
>

export const useLoginWithSelectedAccount = (
  options?: UseLoginWithSelectedAccountOptions
): UseMutationResult<string, AxiosError, LoginWithSelectedAccountRequest> => {
  const { endpoint, loginWithSelectedAccount } = useIamManagerAuthorization()
  const mutationKey = useMemo(() => [endpoint], [endpoint])

  const mutationFn = useCallback(
    async (params: LoginWithSelectedAccountRequest) => {
      const response = await loginWithSelectedAccount(params)

      return response.data.token
    },
    [loginWithSelectedAccount]
  )

  const mutation = useMutation<string, AxiosError, LoginWithSelectedAccountRequest>(
    mutationKey,
    mutationFn,
    {
      ...options,
      onSuccess: token => {
        const paramRedirect = Cookies.get('redirect')
        const queryOperator = paramRedirect?.includes('?') ? '&' : '?'

        Cookies.remove('redirect')

        window.location.href = paramRedirect
          ? `${paramRedirect}${queryOperator}token=${token}`
          : redirectUrl('admin', {
              queryParams: { token }
            })
      }
    }
  )

  return mutation
}
