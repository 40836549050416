import { Box, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import useValidatePasswordRequestContext from 'modules/passwordRequest/hooks/useValidatePasswordRequestContext'
import { useTranslation } from 'hooks/useTranslation'
import { useNavigate } from 'react-router-dom'
import usePasswordRequestContext from 'modules/passwordRequest/hooks/usePasswordRequestContext'
import { useCallback, useEffect } from 'react'
import RegisterNewPasswordForm from './components/RegisterNewPasswordForm'

export default function RegisterNewPassword() {
  const { validatePasswordRequestContextEmail } = useValidatePasswordRequestContext()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    errorRequestPassword: [, setErrorRequestPassword]
  } = usePasswordRequestContext()

  const backNavigate = useCallback(() => {
    setErrorRequestPassword('')
    navigate(-1)
  }, [setErrorRequestPassword, navigate])

  useEffect(() => {
    validatePasswordRequestContextEmail()
  }, [validatePasswordRequestContextEmail])

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        gap="10px"
        marginBottom="30px"
        onClick={() => backNavigate()}
        sx={{ cursor: 'pointer' }}
      >
        <ArrowBackIcon />
        <Typography variant="h5"> {t('Cadastre uma nova senha')}</Typography>
      </Stack>

      <RegisterNewPasswordForm />
    </Box>
  )
}
