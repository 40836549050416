import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import ButtonLink from 'modules/button/components/ButtonLink'
import FormAlertError from 'modules/form/components/FormAlertError'
import FormHelperTextError from 'modules/input/components/FormHelperTextError'
import PasswordInput from 'modules/input/components/PasswordInput'
import PasswordInputFormHelperTextError from 'modules/input/components/PasswordInput/components/PasswordInputFormHelperTextError'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import {
  useAcceptInvite,
  useAcceptingInvite
} from 'services/api/iam/manager/mutations/useAcceptInvite'
import useManagerTokenInviteContext from 'views/invite/hooks/useManagerTokenInviteContext'
import { InviteFormType } from 'views/invite/types/InviteFormType'

type InviteRegisterFormProps = {
  setOpenDeclineInvitationModal: (value: boolean) => void
}

export default function InviteRegisterForm(props: InviteRegisterFormProps) {
  const { setOpenDeclineInvitationModal } = props
  const { t } = useTranslation()
  const { mutateAsync, error, isError } = useAcceptInvite()
  const { token, decodedToken } = useManagerTokenInviteContext()
  const isLoading = useAcceptingInvite()

  const { control, getValues, handleSubmit } = useForm<InviteFormType>({
    defaultValues: {
      name: '',
      password: '',
      acceptTerms: false
    },
    resolver: joiResolver(
      Joi.object({
        name: Joi.string(),
        password: Joi.string()
          .regex(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`])\S{8,}$/
          )
          .required(),
        acceptTerms: Joi.boolean().valid(true).required()
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório'),
        'any.only': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const sendInviteAccept = useCallback(async () => {
    mutateAsync({
      body: {
        user: {
          name: getValues('name'),
          password: getValues('password')
        }
      },
      id: decodedToken!.id,
      token
    })
  }, [mutateAsync, getValues, decodedToken, token])

  const handleSendInviteAccept = useMemo(
    () => handleSubmit(sendInviteAccept),
    [handleSubmit, sendInviteAccept]
  )

  return (
    <Box>
      <Stack gap="10px" marginBottom="30px">
        <Typography variant="h6"> {t('Crie sua conta de usuário')}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {t('Um pequeno passo para uma grande história')}
        </Typography>
      </Stack>

      {isError && (
        <Box marginBottom="25px">
          <FormAlertError message={t(error.response?.data.code || error.message)} />
        </Box>
      )}

      <form>
        <Box>
          <Stack gap="25px" marginBottom="25px">
            <Controller
              control={control}
              name="name"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('Nome completo')}</FormLabel>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Nome completo')}
                    autoComplete="new-password"
                    helperText={
                      <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                    }
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="password"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('Senha de acesso')}</FormLabel>
                  <PasswordInput
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite uma senha de acesso')}
                    autoComplete="new-password"
                    helperText={<PasswordInputFormHelperTextError error={!!error} value={value} />}
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="acceptTerms"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label={
                      <Trans
                        t={t}
                        i18nKey="registerCheckboxMessage"
                        components={{
                          Typography: <Typography variant="caption" />,
                          LinkToTerms: (
                            <ButtonLink
                              sx={{ fontSize: '12px' }}
                              onClick={() =>
                                window.open('https://www.moovin.com.br/termos-de-uso/')
                              }
                            />
                          ),
                          LinkToPolicy: (
                            <ButtonLink
                              sx={{ fontSize: '12px' }}
                              onClick={() =>
                                window.open('https://www.moovin.com.br/politica-privacidade/')
                              }
                            />
                          )
                        }}
                      />
                    }
                  />
                  <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                </div>
              )}
            />
          </Stack>

          <Stack gap="25px">
            <LoadingButton
              variant="contained"
              onClick={handleSendInviteAccept}
              loading={isLoading}
              type="submit"
            >
              {t('Cadastrar')}
            </LoadingButton>

            <Button
              onClick={() => setOpenDeclineInvitationModal(true)}
              color="error"
              variant="outlined"
            >
              {t('Recusar convite')}
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  )
}
