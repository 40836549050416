import { Popover, PopoverProps } from '@mui/material'
import { bindPopover } from 'material-ui-popup-state'
import usePopoverContext from 'components/popover/hooks/usePopoverContext'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { FC, useMemo } from 'react'

type PopoverContentProps = Partial<PopoverProps> & {}

const PopoverContent: FC<PopoverContentProps> = ({ children, ...props }) => {
  const { popupState, triggerMode } = usePopoverContext()

  const PopoverComponent = useMemo(() => {
    switch (triggerMode) {
      case 'hover':
        return HoverPopover

      case 'click':
      default:
        return Popover
    }
  }, [triggerMode])

  return (
    <PopoverComponent {...bindPopover(popupState)} {...props}>
      {children}
    </PopoverComponent>
  )
}

export default PopoverContent
