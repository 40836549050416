import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import useGetSalesInformationByResellerSlug from 'services/ais/partner/queries/useGetSalesInformationByResellerSlug'

export default function RegisterFormTitle() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const { data: salesInformation } = useGetSalesInformationByResellerSlug(
    searchParams.get('reseller') || undefined,
    {
      suspense: true
    }
  )

  const partnerAccountRegisterBonus = useMemo(
    () => salesInformation?.partner.accountRegisterBonus,
    [salesInformation?.partner.accountRegisterBonus]
  )

  return (
    <Stack gap="10px" marginBottom="30px" alignItems={{ xs: 'center', md: 'flex-start' }}>
      {!partnerAccountRegisterBonus ? (
        <Typography variant="h6">{t('Faça seu cadastro, sem compromisso')}</Typography>
      ) : (
        <Trans
          t={t}
          components={{
            Typography: <Typography variant="h4" fontWeight="fontWeightRegular" />,
            b: <b />
          }}
          values={{
            partnerAccountRegisterBonus: partnerAccountRegisterBonus.toLocaleString(
              navigator.language,
              { style: 'currency', currency: 'BRL' }
            )
          }}
          i18nKey="partnerRegistrationMessage"
        />
      )}

      <Typography variant="subtitle1" color="text.secondary">
        {t('Um pequeno passo para uma grande história')}
      </Typography>
    </Stack>
  )
}
