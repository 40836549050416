import { FC, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type AuthenticationErrorBoundaryFallbackProps = {}

const AuthenticationErrorBoundaryFallback: FC<AuthenticationErrorBoundaryFallbackProps> = props => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/')
  }, [navigate])

  return <Fragment />
}

export default AuthenticationErrorBoundaryFallback
