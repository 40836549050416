import { FC } from 'react'
import Button, { ButtonProps } from '../Button'

type ButtonContainedProps = ButtonProps & {
  fontWeight?: 'regular' | 'medium'
}

const ButtonContained: FC<ButtonContainedProps> = ({ fontWeight, ...props }) => {
  return (
    <Button
      {...props}
      sx={[
        {
          color: 'white',
          fontWeight: fontWeight === 'medium' ? 500 : 400,
          transition: ({ transitions }) => transitions.create(['background-color']),
          width: '100%',
          borderRadius: '3px',
          height: '41px'
        },
        props.color === 'error'
          ? {
              backgroundColor: 'error.main',
              ':hover': {
                borderColor: 'error.main',
                backgroundColor: 'error.main'
              },
              ':focus': {
                borderColor: '#FF5C7A',
                backgroundColor: '#FF5C7A'
              },
              ':active': {
                borderColor: '#FF859B',
                backgroundColor: '#FF859B'
              }
            }
          : {
              ':hover': {
                borderColor: 'primary.dark',
                backgroundColor: 'primary.dark'
              },
              ':focus': {
                borderColor: '#0DBF7B',
                backgroundColor: '#0DBF7B'
              },
              ':active': {
                borderColor: '#0FD287',
                backgroundColor: '#0FD287'
              },
              ':disabled': {
                borderColor: '#0FD287',
                backgroundColor: '#0FD287'
              }
            }
      ]}
      variant="contained"
    />
  )
}

export default ButtonContained
