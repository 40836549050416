import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { LoginWithSelectedAccountRequest } from './interfaces/LoginWithSelectedAccountRequest'
import { LoginWithSelectedAccountResponse } from './interfaces/LoginWithSelectedAccountResponse'

export const useIamManagerAuthorization = () => {
  const endpoint = 'iam-manager/authorization'

  const loginWithSelectedAccount = useCallback(
    async (params: LoginWithSelectedAccountRequest) =>
      axiosInstance.post<LoginWithSelectedAccountResponse>(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
            '1eg-Account-Id': params.accountId
          }
        }
      ),
    []
  )

  return {
    endpoint,
    loginWithSelectedAccount
  }
}
