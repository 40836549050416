import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { MeGetResponse } from './interfaces/MeGetResponse'

export const useIamManagerMe = () => {
  const endpoint = 'iam-manager/me'

  const get = useCallback(
    async (token: string) =>
      axiosInstance.get<MeGetResponse>(endpoint, {
        headers: { 'X-Authorization': `Bearer ${token}` }
      }),
    []
  )

  return {
    endpoint,
    get
  }
}
