import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import { useNavigate } from 'react-router-dom'
import ChooseAccountList from './components/ChooseAccountList'
import useDecodedToken from 'modules/authentication/hooks/useDecodedToken'

export default function ChooseAccount() {
  useDecodedToken()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="15px"
      >
        <Typography variant="h5">{t('Lojas')}</Typography>

        <Button variant="contained" onClick={() => navigate('/create-account')}>
          {t('Criar uma nova loja')}
        </Button>
      </Stack>

      <ChooseAccountList />
    </Box>
  )
}
