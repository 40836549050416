import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, FormLabel, Stack } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import FormAlertError from 'modules/form/components/FormAlertError'
import PasswordInput from 'modules/input/components/PasswordInput'
import PasswordInputFormHelperTextError from 'modules/input/components/PasswordInput/components/PasswordInputFormHelperTextError'
import usePasswordRequestContext from 'modules/passwordRequest/hooks/usePasswordRequestContext'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoverPassword } from 'services/api/iam/manager/mutations/useRecoverPassword'
import { RegisterNewPasswordFormType } from 'views/registerNewPassword/types/RegisterNewPasswordFormType'

export default function RegisterNewPasswordForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [passwordsNotMatch, setPasswordsNotMatch] = useState<boolean>(false)
  const {
    email: [email],
    password: [, setPassword],
    errorRequestPassword: [, setErrorRequestPassword]
  } = usePasswordRequestContext()
  const validateField = Joi.string()
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`])\S{8,}$/)
    .required()
  const {
    mutateAsync,
    isLoading,
    error: errorRecoverPassword,
    isError: isErrorRecoverPassword
  } = useRecoverPassword({
    onSuccess: () => {
      setPassword(getValues('password'))
    },
    onError: error => {
      if (error.response?.data.code === '404.userDataNotFoundException') {
        setErrorRequestPassword('Usuário não encontrado')

        navigate('/')
      }
    }
  })

  const { control, getValues, handleSubmit } = useForm<RegisterNewPasswordFormType>({
    defaultValues: {
      password: '',
      repeatedPassword: ''
    },
    resolver: joiResolver(
      Joi.object({
        password: validateField,
        repeatedPassword: validateField
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const sendRequestPassword = useCallback(async () => {
    setPasswordsNotMatch(false)

    if (getValues('password') !== getValues('repeatedPassword')) {
      setPasswordsNotMatch(true)

      return
    }

    mutateAsync({ ...getValues(), email: email ? email : '' })
  }, [mutateAsync, getValues, email])

  const handleSendRequestPassword = useMemo(
    () => handleSubmit(sendRequestPassword),
    [handleSubmit, sendRequestPassword]
  )

  const error = useMemo(() => {
    return isErrorRecoverPassword || passwordsNotMatch
  }, [isErrorRecoverPassword, passwordsNotMatch])

  const errorMessage = useMemo(() => {
    if (passwordsNotMatch) {
      return t('As senhas não coincidem. Tente novamente.')
    }

    if (isErrorRecoverPassword) {
      return t(errorRecoverPassword.response?.data.code || errorRecoverPassword.message)
    }

    return ''
  }, [passwordsNotMatch, t, isErrorRecoverPassword, errorRecoverPassword])

  return (
    <Box>
      {error && (
        <Box marginBottom="20px">
          <FormAlertError message={errorMessage} />
        </Box>
      )}

      <form>
        <Stack gap="25px">
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl required>
                <FormLabel>{t('Senha')}</FormLabel>
                <PasswordInput
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  placeholder={t('Digite sua senha')}
                  helperText={<PasswordInputFormHelperTextError error={!!error} value={value} />}
                />
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="repeatedPassword"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl required>
                <FormLabel>{t('Repita a senha')}</FormLabel>
                <PasswordInput
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  placeholder={t('Repita a nova senha para confirmar')}
                  helperText={<PasswordInputFormHelperTextError error={!!error} value={value} />}
                />
              </FormControl>
            )}
          />

          <LoadingButton
            loading={isLoading}
            onClick={handleSendRequestPassword}
            type="submit"
            variant="contained"
            size="large"
          >
            {t('Continuar')}
          </LoadingButton>
        </Stack>
      </form>
    </Box>
  )
}
