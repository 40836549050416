import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { joiResolver } from '@hookform/resolvers/joi'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import ButtonLink from 'modules/button/components/ButtonLink'
import FormAlertError from 'modules/form/components/FormAlertError'
import FormHelperTextError from 'modules/input/components/FormHelperTextError'
import PasswordInput from 'modules/input/components/PasswordInput'
import PasswordInputFormHelperTextError from 'modules/input/components/PasswordInput/components/PasswordInputFormHelperTextError'
import { Fragment, useCallback, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useRegister, useRegistering } from 'services/api/iam/manager/mutations/useRegister'
import { isValidEmail } from 'utils'
import { RegisterFormType } from 'views/register/types/RegisterFormType'

export default function RegisterForm() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const {
    mutateAsync: registerAccount,
    error: registerError,
    isError: isRegisterError
  } = useRegister()
  const gtmDispatch = useGTMDispatch()
  const isLoading = useRegistering()

  const { control, handleSubmit } = useForm<RegisterFormType>({
    defaultValues: {
      accountName: '',
      userName: '',
      userEmail: '',
      userPassword: '',
      acceptTerms: false,
      utm: {
        source: searchParams.get('utm_source') || undefined,
        medium: searchParams.get('utm_medium') || undefined,
        campaign: searchParams.get('utm_campaign') || undefined,
        term: searchParams.get('utm_term') || undefined,
        content: searchParams.get('utm_content') || undefined
      },
      resellerCode: searchParams.get('reseller') || undefined
    },
    resolver: joiResolver(
      Joi.object({
        accountName: Joi.string(),
        userName: Joi.string(),
        userEmail: Joi.string().custom((value, helper) => {
          if (!isValidEmail(value)) {
            return helper.message({ custom: t('E-mail inválido') })
          }

          return value
        }),
        userPassword: Joi.string()
          .regex(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`])\S{8,}$/
          )
          .required(),
        acceptTerms: Joi.boolean().valid(true).required(),
        utm: Joi.object({
          source: Joi.string().optional(),
          medium: Joi.string().optional(),
          campaign: Joi.string().optional(),
          term: Joi.string().optional(),
          content: Joi.string().optional()
        })
          .optional()
          .allow(null),
        resellerCode: Joi.string().optional()
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório'),
        'any.only': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const sendRegister = useCallback<SubmitHandler<RegisterFormType>>(
    data => {
      registerAccount(data).then(() =>
        gtmDispatch({ event: 'signup', value: JSON.stringify(data) })
      )
    },
    [registerAccount, gtmDispatch]
  )

  const handleSendRegister = useMemo(
    () => handleSubmit(sendRegister, err => console.info(err)),
    [handleSubmit, sendRegister]
  )

  return (
    <Box marginBottom="30px">
      <Stack gap="20px">
        {isRegisterError && (
          <FormAlertError message={t(registerError.response?.data.code || registerError.message)} />
        )}

        <form>
          <Stack gap="30px">
            <Controller
              control={control}
              name="accountName"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('Nome da sua loja')}</FormLabel>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite o nome da sua loja')}
                    helperText={
                      <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                    }
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="userName"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('Nome completo')}</FormLabel>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite seu nome completo')}
                    helperText={
                      <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                    }
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="userEmail"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('E-mail')}</FormLabel>
                  <TextField
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite seu e-mail')}
                    helperText={
                      <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                    }
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="userPassword"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormControl required>
                  <FormLabel>{t('Senha')}</FormLabel>
                  <PasswordInput
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    placeholder={t('Digite sua senha')}
                    autoComplete="new-password"
                    helperText={<PasswordInputFormHelperTextError error={!!error} value={value} />}
                  />
                </FormControl>
              )}
            />

            <Controller
              control={control}
              name="acceptTerms"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Stack gap="10px">
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label={
                      <Trans
                        t={t}
                        i18nKey="registerCheckboxMessage"
                        components={{
                          Typography: <Typography variant="caption" />,
                          LinkToTerms: (
                            <ButtonLink
                              sx={{ fontSize: '12px' }}
                              onClick={() =>
                                window.open('https://www.moovin.com.br/termos-de-uso/')
                              }
                            />
                          ),
                          LinkToPolicy: (
                            <ButtonLink
                              sx={{ fontSize: '12px' }}
                              onClick={() =>
                                window.open('https://www.moovin.com.br/politica-privacidade/')
                              }
                            />
                          )
                        }}
                      />
                    }
                  />
                  <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                </Stack>
              )}
            />

            <LoadingButton
              id="btnCreateAccountTrial"
              onClick={handleSendRegister}
              loading={isLoading}
              type="submit"
              size="large"
              variant="contained"
            >
              {t('Iniciar teste gratuito')}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Box>
  )
}
