import PopoverContext, { PopoverContextStore } from 'components/popover/contexts/PopoverContext'
import { useContext } from 'react'

const usePopoverContext = () => {
  const popoverContext = useContext<PopoverContextStore | undefined>(PopoverContext)

  if (!popoverContext)
    throw new Error('usePopoverContext hook must be used inside TableContextProvider.')

  return popoverContext
}

export default usePopoverContext
