import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { GetInviteStatusParams } from '../interfaces/GetInviteStatusParams'
import { GetInviteStatusResponse } from '../interfaces/GetInviteStatusResponse'
import { useIamManagerInvite } from '../useIamManagerInvite'
import { useNavigate } from 'react-router-dom'
import { toastError } from 'utils/toast/Toast'
import { useTranslation } from 'hooks/useTranslation'

type UseGetInviteStatusResult = UseQueryResult<GetInviteStatusResponse>

export const useGetInviteStatus = (params: GetInviteStatusParams): UseGetInviteStatusResult => {
  const { endpoint, getInviteStatus } = useIamManagerInvite()
  const queryKey = useMemo(() => [endpoint], [endpoint])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const queryFn = useCallback(async () => {
    return (await getInviteStatus(params)).data
  }, [getInviteStatus, params])

  const query = useQuery<GetInviteStatusResponse>({
    queryKey,
    queryFn,
    enabled: !!params.id && !!params.token,
    onError: () => {
      navigate('/')
      toastError(t('Erro ao carregar dados do convite!'))
    }
  })

  return query
}
