import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { MeGetResponse } from '../interfaces/MeGetResponse'
import { useIamManagerMe } from '../useIamManagerMe'

type UseGetMeResult = UseQueryResult<MeGetResponse>

export const useGetMe = (token: string | undefined): UseGetMeResult => {
  const { endpoint, get } = useIamManagerMe()
  const queryKey = useMemo(() => [endpoint], [endpoint])

  const queryFn = useCallback(async () => {
    return (await get(token!)).data
  }, [get, token])

  const query = useQuery<MeGetResponse>({
    queryKey,
    queryFn,
    enabled: !!token?.length
  })

  return query
}
