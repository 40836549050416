import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { useIamManagerAuthentication } from '../useIamManagerAuthentication'
import useAuthenticationContext from 'modules/authentication/hooks/useAuthenticationContext'
import useDecodedToken from 'modules/authentication/hooks/useDecodedToken'
import md5 from 'md5'

export type UseLogoutUserOptions = UseMutationOptions<void, AxiosError, void>

export const useLogoutUser = (
  options?: UseLogoutUserOptions
): UseMutationResult<void, AxiosError, void> => {
  const { endpoint, logoutUser } = useIamManagerAuthentication()
  const mutationKey = useMemo(() => [endpoint], [endpoint])
  const {
    token: [token, setToken]
  } = useAuthenticationContext()
  const decodedToken = useDecodedToken()

  const mutationFn = useCallback(async () => {
    const formattedDecodedToken = decodedToken
    delete formattedDecodedToken['iat']

    await logoutUser(md5(JSON.stringify(formattedDecodedToken)), token?.token!)
  }, [logoutUser, decodedToken, token])

  const mutation = useMutation<void, AxiosError, void>(mutationKey, mutationFn, {
    onSuccess: () => {
      setToken(undefined)
    }
  })

  return mutation
}
