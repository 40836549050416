import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { VerifyCodeRecoverPasswordRequest } from '../interfaces/VerifyCodeRecoverPasswordRequest'
import { useIamManagerPassword } from '../useIamManagerPassword'
import { useNavigate } from 'react-router-dom'
import { toastSuccess } from 'utils/toast/Toast'
import { useTranslation } from 'hooks/useTranslation'

export type UseVerifyCodeRecoverPasswordOptions = UseMutationOptions<
  void,
  AxiosError,
  VerifyCodeRecoverPasswordRequest
>

export const useVerifyCodeRecoverPassword = (
  options?: UseVerifyCodeRecoverPasswordOptions
): UseMutationResult<void, AxiosError, VerifyCodeRecoverPasswordRequest> => {
  const { endpoint, verifyCodeRecoverPassword } = useIamManagerPassword()
  const mutationKey = useMemo(() => [endpoint], [endpoint])
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mutationFn = useCallback(
    async (body: VerifyCodeRecoverPasswordRequest) => {
      await verifyCodeRecoverPassword(body)
    },
    [verifyCodeRecoverPassword]
  )

  const mutation = useMutation<void, AxiosError, VerifyCodeRecoverPasswordRequest>(
    mutationKey,
    mutationFn,
    {
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context)
        toastSuccess(t('Senha alterada com sucesso!'))
        navigate('/')
      }
    }
  )

  return mutation
}
