import { useCallback } from 'react'
import { axiosInstance } from 'services/api/axiosInstance'
import { LoginResponse } from './interfaces/LoginResponse'
import { LoginRequest } from './interfaces/LoginRequest'

export const useIamManagerAuthentication = () => {
  const endpoint = 'iam-manager/authentication'

  const login = useCallback(
    async (auth: LoginRequest) =>
      axiosInstance.post<LoginResponse>(
        endpoint,
        {},
        {
          auth: { username: auth.username, password: auth.password },
          headers: {
            '1eg-User-RoleType': 'MANAGER'
          }
        }
      ),
    []
  )

  const logoutUser = useCallback(async (formattedDecodedToken: string, token: string) => {
    axiosInstance.delete(`${endpoint}/${formattedDecodedToken}`, {
      headers: { 'X-Authorization': `Bearer ${token}` }
    })
  }, [])

  const authentication2fa = useCallback((token: string, sixDigitsCode: string) => {
    return axiosInstance.post(
      `${endpoint}/2fa`,
      { sixDigitsCode },
      {
        headers: { Authorization: `Bearer ${token}`, '1eg-User-RoleType': 'MANAGER' }
      }
    )
  }, [])

  return {
    endpoint,
    login,
    logoutUser,
    authentication2fa
  }
}
