import AuthenticationContext from 'modules/authentication/contexts/AuthenticationContext'
import { TokenData } from 'modules/authentication/contexts/AuthenticationContext/AuthenticationContext'
import { FC, useState } from 'react'

const AuthenticationProvider: FC = ({ children }) => {
  const token = useState<TokenData>()

  return (
    <AuthenticationContext.Provider value={{ token }}>{children}</AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
