import { useCallback } from 'react'
import usePasswordRequestContext from '../usePasswordRequestContext'

const useValidatePasswordRequestContext = () => {
  const {
    email: [email]
  } = usePasswordRequestContext()

  const validatePasswordRequestContextEmail = useCallback(() => {
    if (!email) {
      throw new Error('Undefined email')
    }
  }, [email])

  return {
    validatePasswordRequestContextEmail
  }
}

export default useValidatePasswordRequestContext
