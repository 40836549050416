import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Joi from 'joi'
import ButtonLink from 'modules/button/components/ButtonLink'
import FormAlertError from 'modules/form/components/FormAlertError'
import FormHelperTextError from 'modules/input/components/FormHelperTextError'
import usePasswordRequestContext from 'modules/passwordRequest/hooks/usePasswordRequestContext'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useRecoverPassword } from 'services/api/iam/manager/mutations/useRecoverPassword'
import { useVerifyCodeRecoverPassword } from 'services/api/iam/manager/mutations/useVerifyCodeRecoverPassword'
import { toastSuccess } from 'utils/toast/Toast'
import { VerificationCodeType } from 'views/verificationCode/types/VerificationCodeFormType'

export default function VerificationCodeForm() {
  const { t } = useTranslation()
  const {
    email: [email],
    password: [password],
    errorRequestPassword: [, setErrorRequestPassword]
  } = usePasswordRequestContext()
  const {
    mutateAsync: mutateAsyncVerifyCodeRecoverPassword,
    isLoading: isLoadingVerifyCodeRecoverPassword,
    error: errorVerifyCodeRecoverPassword,
    isError: isErrorVerifyCodeRecoverPassword
  } = useVerifyCodeRecoverPassword({
    onSuccess: () => {
      setErrorRequestPassword('')
    }
  })
  const {
    mutateAsync: mutateAsyncRecoverPassword,
    isLoading: isLoadingRecoverPassword,
    error: errorRecoverPassword,
    isError: isErrorRecoverPassword
  } = useRecoverPassword({
    onSuccess: () => {
      toastSuccess(t('Código reenviado para o seu e-mail!'))
    }
  })

  const { control, getValues, handleSubmit } = useForm<VerificationCodeType>({
    defaultValues: {
      verificationCode: ''
    },
    resolver: joiResolver(
      Joi.object({
        verificationCode: Joi.string()
      }).messages({
        'string.empty': t('Campo de preenchimento obrigatório')
      })
    )
  })

  const sendVerifyCode = useCallback(async () => {
    mutateAsyncVerifyCodeRecoverPassword({ ...getValues(), email: email ? email : '' })
  }, [mutateAsyncVerifyCodeRecoverPassword, getValues, email])

  const handleSendVerifyCode = useMemo(
    () => handleSubmit(sendVerifyCode),
    [handleSubmit, sendVerifyCode]
  )

  const sendRequestPassword = useCallback(() => {
    mutateAsyncRecoverPassword({
      email: email ? email : '',
      password: password ? password : ''
    })
  }, [mutateAsyncRecoverPassword, email, password])

  const error = useMemo(() => {
    return isErrorVerifyCodeRecoverPassword
  }, [isErrorVerifyCodeRecoverPassword])

  const errorMessage = useMemo(() => {
    if (isErrorVerifyCodeRecoverPassword) {
      return t(
        errorVerifyCodeRecoverPassword.response?.data.code || errorVerifyCodeRecoverPassword.message
      )
    }

    if (isErrorRecoverPassword) {
      return t(errorRecoverPassword.response?.data.code || errorRecoverPassword.message)
    }

    return ''
  }, [
    isErrorVerifyCodeRecoverPassword,
    errorVerifyCodeRecoverPassword,
    t,
    isErrorRecoverPassword,
    errorRecoverPassword
  ])

  return (
    <Box>
      {error && (
        <Box marginBottom="20px">
          <FormAlertError message={errorMessage} />
        </Box>
      )}

      {isLoadingRecoverPassword && (
        <Box display="flex" justifyContent="center" padding="100px">
          <CircularProgress size={60} sx={[{ color: 'primary.main' }]} />
        </Box>
      )}

      {!isLoadingRecoverPassword && (
        <Stack gap="25px">
          <form>
            <Stack gap="25px">
              <Controller
                control={control}
                name="verificationCode"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <FormControl required>
                    <FormLabel>{t('Código de confirmação')}</FormLabel>
                    <TextField
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      placeholder={t('Digite o código que recebeu. Exemplo: 000000')}
                      helperText={
                        <FormHelperTextError error={!!error}>{error?.message}</FormHelperTextError>
                      }
                    />
                  </FormControl>
                )}
              />

              <LoadingButton
                loading={isLoadingVerifyCodeRecoverPassword}
                onClick={handleSendVerifyCode}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('Confirmar')}
              </LoadingButton>
            </Stack>
          </form>

          <Stack direction="row" gap="5px" justifyContent={{ xs: 'center', md: 'flex-start' }}>
            <Typography variant="body2">{t('Não recebeu o código?')}</Typography>
            <ButtonLink onClick={() => sendRequestPassword()}>{t('Enviar novamente')}</ButtonLink>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}
