import { styled } from '@mui/material'

const EllipsisLoaderRoot = styled('div', {
  name: 'EllipsisLoader',
  slot: 'Root'
})({
  display: 'inline-block',
  position: 'relative',
  width: 64,
  height: 64
})

const EllipsisLoaderBall = styled('div', {
  name: 'EllipsisLoader',
  slot: 'Ball'
})(({ theme }) => ({
  background: theme.palette.primary.main,
  position: 'absolute',
  top: 27,
  width: 11,
  height: 11,
  borderRadius: '50%',
  animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
  '&:nth-child(1)': {
    left: 6,
    animation: 'ellipsis-indicator-1 0.6s infinite'
  },
  '&:nth-child(2)': {
    left: 6,
    animation: 'ellipsis-indicator-2 0.6s infinite'
  },
  '&:nth-child(3)': {
    left: 26,
    animation: 'ellipsis-indicator-2 0.6s infinite'
  },
  '&:nth-child(4)': {
    left: 45,
    animation: 'ellipsis-indicator-3 0.6s infinite'
  },

  '@keyframes ellipsis-indicator-1': {
    '0%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },

  '@keyframes ellipsis-indicator-2': {
    '0%': {
      transform: 'translate(0, 0)'
    },
    '100%': {
      transform: 'translate(19px, 0)'
    }
  },

  '@keyframes ellipsis-indicator-3': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(0)'
    }
  }
}))

export default function EllipsisLoader() {
  return (
    <EllipsisLoaderRoot>
      {[1, 2, 3, 4].map(ball => (
        <EllipsisLoaderBall key={ball} />
      ))}
    </EllipsisLoaderRoot>
  )
}
