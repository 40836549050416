import { FC } from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'

export type ButtonProps = MuiButtonProps & LoadingButtonProps

const Button: FC<ButtonProps> = props => {
  return props.loading ? <LoadingButton {...props} /> : <MuiButton {...props} />
}

export default Button
