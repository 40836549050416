import { createContext, Dispatch, SetStateAction } from 'react'

export type PasswordRequestContextStore = {
  email: [string | undefined, Dispatch<SetStateAction<string | undefined>>]
  password: [string | undefined, Dispatch<SetStateAction<string | undefined>>]
  errorRequestPassword: [string | undefined, Dispatch<SetStateAction<string | undefined>>]
}

const PasswordRequestContext = createContext<PasswordRequestContextStore | undefined>(undefined)

export default PasswordRequestContext
