import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query'
import { useIamManagerAuthentication } from '../useIamManagerAuthentication'
import { LoginResponse } from '../interfaces/LoginResponse'

export type UseAuthenticate2FAOptions = UseMutationOptions<LoginResponse, AxiosError, Variables>

type Variables = {
  token: string
  sixDigitsCode: string
}

export const useAuthenticate2FA = (
  options?: UseAuthenticate2FAOptions
): UseMutationResult<LoginResponse, AxiosError, Variables> => {
  const { endpoint, authentication2fa } = useIamManagerAuthentication()
  const mutationKey = useMemo(() => [endpoint], [endpoint])

  const mutationFn = useCallback(
    async (data: Variables) => {
      const response = await authentication2fa(data.token, data.sixDigitsCode)

      return response.data
    },
    [authentication2fa]
  )

  const mutation = useMutation<LoginResponse, AxiosError, Variables>({
    ...options,
    mutationKey,
    mutationFn
  })

  return mutation
}
