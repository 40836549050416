import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'hooks/useTranslation'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import LoginForm from './components/LoginForm'
import ButtonLink from 'modules/button/components/ButtonLink'

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const param = searchParams.get('redirect')

    if (param) {
      Cookies.set('redirect', param)
    }
  }, [searchParams])

  return (
    <Box>
      <Stack gap="10px" marginBottom="60px" alignItems={{ xs: 'center', md: 'flex-start' }}>
        <Typography variant="h4">{t('Que bom ter você aqui!')}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {t('Faça login para continuar.')}
        </Typography>
      </Stack>

      <LoginForm />

      <Stack flexDirection={{ md: 'row' }} gap="5px" alignItems={{ xs: 'center' }} marginTop="30px">
        <Typography variant="body2">{t('Ainda não possui conta na Moovin?')}</Typography>
        <ButtonLink onClick={() => navigate('/register')}>{t('Cadastre-se agora')}</ButtonLink>
      </Stack>
    </Box>
  )
}
