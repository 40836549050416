import { useCallback } from 'react'
import { RegisterRequestDTO } from '../interfaces/RegisterRequestDTO'
import { RegisterFormType } from 'views/register/types/RegisterFormType'

export const useRegisterParser = () => {
  const parseRegister = useCallback<(domain: RegisterFormType) => RegisterRequestDTO>(
    domain => ({
      user: {
        name: domain.userName.trim(),
        email: domain.userEmail.trim(),
        password: domain.userPassword
      },
      account: {
        name: domain.accountName.trim(),
        utm: domain.utm,
        resellerCode: domain.resellerCode
      }
    }),
    []
  )

  return { parseRegister }
}
