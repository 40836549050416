import { AxiosError } from 'axios'
import { useCallback, useMemo } from 'react'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { CreateAccountParams, useIamManagerAccount } from '../useIamManagerAccount'
import Cookies from 'js-cookie'
import { redirectUrl } from '@1eg/lib-core-web-components-js'

export type UseCreateAccountOptions = UseMutationOptions<string, AxiosError, CreateAccountParams>

export const useCreateAccount = (
  options?: UseCreateAccountOptions
): UseMutationResult<string, AxiosError, CreateAccountParams> => {
  const { endpoint, createAccount } = useIamManagerAccount()
  const mutationKey = useMemo(() => [endpoint], [endpoint])

  const mutationFn = useCallback(
    async (params: CreateAccountParams) => {
      const utm = Cookies.get('utm')
      const response = await createAccount({
        token: params.token,
        body: { ...params.body, utm: utm ? JSON.parse(utm) : undefined }
      })

      return response.data.token
    },
    [createAccount]
  )

  const mutation = useMutation<string, AxiosError, CreateAccountParams>(mutationKey, mutationFn, {
    ...options,
    onSuccess: token => {
      const paramRedirect = Cookies.get('redirect')
      const queryOperator = paramRedirect?.includes('?') ? '&' : '?'

      Cookies.remove('redirect')
      Cookies.remove('utm')

      window.location.href = paramRedirect
        ? `${paramRedirect}${queryOperator}token=${token}`
        : redirectUrl('admin', {
            queryParams: { token }
          })
    }
  })

  return mutation
}
