import { usePopupState } from 'material-ui-popup-state/hooks'
import PopoverContext from 'components/popover/contexts/PopoverContext'
import { FC } from 'react'

type PopoverProviderProps = {
  triggerMode?: 'click' | 'hover'
}

const PopoverProvider: FC<PopoverProviderProps> = ({ children, triggerMode = 'click' }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'popover' })

  return (
    <PopoverContext.Provider value={{ popupState, triggerMode }}>
      {children}
    </PopoverContext.Provider>
  )
}

export default PopoverProvider
