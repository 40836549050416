import { bindTrigger, bindHover } from 'material-ui-popup-state'
import usePopoverContext from 'components/popover/hooks/usePopoverContext'
import { FC, useMemo } from 'react'

const PopoverTrigger: FC = ({ children }) => {
  const { popupState, triggerMode } = usePopoverContext()

  const triggerHandler = useMemo(() => {
    switch (triggerMode) {
      case 'hover':
        return bindHover

      case 'click':
      default:
        return bindTrigger
    }
  }, [triggerMode])

  return <div {...triggerHandler(popupState)}>{children}</div>
}

export default PopoverTrigger
