import { styled } from '@mui/material'
import EllipsisLoader from '../EllipsisLoader/EllipsisLoader'

const AppLoaderRoot = styled('div', {
  name: 'AppLoader',
  slot: 'Root'
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw'
})

export default function AppLoader() {
  return (
    <AppLoaderRoot>
      <EllipsisLoader />
    </AppLoaderRoot>
  )
}
