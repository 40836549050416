import AuthenticationContext, {
  AuthenticationContextStore
} from 'modules/authentication/contexts/AuthenticationContext'
import { useContext } from 'react'

const useAuthenticationContext = () => {
  const authenticationContextData = useContext<AuthenticationContextStore | undefined>(
    AuthenticationContext
  )

  if (!authenticationContextData)
    throw new Error(
      'useAuthenticationContext hook must be used inside AuthenticationContextProvider.'
    )

  return authenticationContextData
}

export default useAuthenticationContext
